<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="80"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        label="回收商名称"
        slot="merchantId"
        style="margin: 0"
        label-width="100px"
      >
        <el-select
        filterable
          :popper-append-to-body="false"
          v-model="merchantId"
          placeholder="请选择或输入回收商家查询"
          clearable
        >
          <el-option
            v-for="item in merchantList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="账单类型"
        slot="typeCode"
        style="margin: 0"
      >
        <el-select
        filterable
          :popper-append-to-body="false"
          v-model="typeCode"
          placeholder="请选择或输入账单类型查询"
          clearable
        >
          <el-option
            v-for="(item,index) in upDownTypeList"
            :key="index"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import moment from "moment";

export default {
  name: "Form",
  props: {
    tabActive: [String]
  },
  data() {
    return {
      initData: {
        entryTime: [
          moment()
            .day(moment().day())
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      merchantList: [],
      merchantId: "",
      formItemList: [
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间:",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          lableWidth: "60px",
          clearable: true,
        },
        // {
        //   key: "merchantName",
        //   type: "input",
        //   labelName: "回收商名称:",
        //   placeholder: "请输入商家名称查询",
        //   lableWidth: "100px"
        // },
        {slotName: "merchantId"},
        {
          key: "companyId",
          clear: true,
          type: "selectFilterable",
          labelName: "门店商名称",
          placeholder: "请选择门店商名称",
          lableWidth: "100px",
          option: [],
          clearFun: () => false,
        },
        {
          key: "contactsPhone",
          type: "input",
          labelName: "联系电话:",
          placeholder: "请输入联系电话查询",
        },
        {
          key: "note",
          type: "input",
          labelName: "备注说明:",
          placeholder: "请输入备注说明查询",
          maxlength: 20,
        },
        {slotName: "typeCode"},
        {
          labelName: "核账状态",
          key: 'approvedType',
          type: "select",
          placeholder: "请选择",
          option: [
            {
              label: '未核账',
              value: "00"
            },
            {
              label: '已核账（已通过）',
              value: "01"
            },
            {
              label: '已核账（未通过）',
              value: "02"
            }
          ]
        }
      ],
      upDownTypeList: [],
      typeCode: "",
    };
  },
  created() {
    if(this.$route.query.merchantId){
      this.merchantId = Number(this.$route.query.merchantId)
    }
    this.getMerchantSelect()
    this.getCompanySelect()
    this.getUpDownTypeList()
    this.handleConfirm(this.initData);
  },
  watch: {
    tabActive(val){
      if(val){
        this.typeCode = ""
      }
      this.getUpDownTypeList()
    }
  },
  methods: {
    // 回收商家下拉
    getMerchantSelect(){
      _api.listSimpleMerchantView({pageNum: 1, pageSize: 9999}).then(res => {
        this.merchantList = res.data
        // const merchantIndex = this.formItemList.findIndex(
        //   (v) => v.key === "merchantId"
        // );
        // this.formItemList[merchantIndex].option = res.data.map((v) => {
        //   return {
        //     label: v.merchantName,
        //     value: v.merchantId,
        //   };
        // });
      })
    },
    //获取门店商家下拉
    getCompanySelect() {
      _api.getSelectList().then(res => {
        const companyIdIndex = this.formItemList.findIndex(
          (v) => v.key === "companyId"
        );
        this.formItemList[companyIdIndex].option = res.data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      })
    },  
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {
        typeCode: this.typeCode,
        merchantId: this.merchantId,
      };
      if (data) {
        SeachParams = {
          merchantId: this.merchantId,
          typeCode: this.typeCode,
          contactsPhone: data.contactsPhone || "",
          // merchantName: data.merchantName || "",
          companyId: data.companyId || "",
          note: data.note || "",
          approvedType:data.approvedType??"",
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams. disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      this.SeachParams = SeachParams
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 获取上下账类型列表下拉
    getUpDownTypeList() {
      _api.getUpDownTypeList({opt:this.tabActive}).then((res) => {
        if (res.code === 1) {
          this.upDownTypeList = res.data;
        }
      });
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
