<template>
  <div class="class-list">
    <GlobalInfoBar
      title="回收商账务"
      content="管理回收商家充值业务，余额上账、下账等"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From :tabActive="tabActive"/>
      </div>
      <Table @tabChange="tabChange"/>
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    From,
    Table
  },
  data() {
    return {
      tabActive: "",
    };
  },
  methods: {
    tabChange(type){
      this.tabActive = type
    }
  },
};
</script>

<style>
</style>